<template>
  <c-box style="position: relative; width: 100%">
    <!-- Header Section -->
    <c-flex
      class="header"
      :align="['start', 'start', 'space-between']"
      :flexDirection="['column', 'column', 'row']"
      :justify="space-between"
      mt="4"
      mb="4"
      :class="{'show-header-back': !hideHeaderBackground, 'hide-header-back': hideHeaderBackground}"
    >
      <!-- Left Section (Title) -->
      <c-flex
        pt="10px"
        w="100%"
        :justify="['space-between', 'start', 'space-between']"
        align="center"
      >
        <!-- Title -->
        <c-heading
          pt="2"
          fontSize="2xl"
          fontWeight="700"
        
          :ml="isMobile ? '0' : '3'"
          :mb="['2', '2', '0']"
        >
          Explore
        </c-heading>
        <HeaderOptions v-if="isMobile" />
      </c-flex>

      <!-- Right Section (Search and Header Options) -->
      <c-flex w="100%" style="align-items: center; justify: end; height: 50px;" :justify="['start', 'end', 'end']">
        <search @hide-sidebar="handleHideSidebar" />
        <HeaderOptions v-if="!isMobile" />
      </c-flex>
    </c-flex>

    <!-- Main Content -->
    <c-flex :mx="isMobile || isBigMobile ? '2' : '0'" style="z-index: 99" :flexDirection="['column', 'column', 'row']">
      <c-flex :mb="isMobile ? '100px': ''" :w="isMobile ? '100%' : '70%'" h="100%" :flex="hideSidebar ? '1' : '3'">
        <router-view />
      </c-flex>
      <!-- Regular Sidebar (Tablet and Desktop) -->
      <c-flex
        v-if="!hideSidebar && !isMobile"
        ml="5"
        :flex="hideSidebar ? '0' : isBigMobile ? '2' : '3'"
      >
        <sidebar />
      </c-flex>
    </c-flex>

    <!-- Bottom Drawer Sidebar (Mobile Only) -->
    <c-box
      v-if="!hideSidebar && isMobile"
      class="drawer"
      :style="{ height: drawerHeight + 'px' }"
      @mousedown="startDrag"
      @touchstart="startDrag"
    >
      <!-- Drawer Handle -->
      <c-box
        class="drawer-handle"
        @click="toggleDrawerHeight"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </c-box>

      <!-- Sidebar Content -->
       <c-flex justify="center">
        <sidebar />
       </c-flex>
    </c-box>
  </c-box>
</template>

<script>
import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import Sidebar from './Sidebar.vue';
import Search from '@/views/App/Explore/Search.vue';
import { EventBus } from "@/eventBus";

export default {
  name: 'Layout',
  components: {
    Search,
    HeaderOptions,
    Sidebar,
  },
  data() {
    return {
      hideSidebar: false,
      hideHeaderBackground: false,
      drawerHeight: 200, // Initial height of the drawer
      isDragging: false,
      startY: 0,
      startHeight: 0,
      isMobile: false, // Track if the screen is mobile
      isBigMobile: false
    };
  },
  methods: {
    moveToSavedPage() {
      this.$router.push('/saved');
    },
    handleHideSidebar(status) {
      this.hideSidebar = status;
    },
    toggleDrawerHeight() {
      // Toggle between half-screen and full-screen
      this.drawerHeight = this.drawerHeight === 200 ? window.innerHeight * 0.8 : 200;
    },
    startDrag(event) {
      this.isDragging = true;
      this.startY = event.clientY || event.touches[0].clientY;
      this.startHeight = this.drawerHeight;

      // Add event listeners for dragging
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('touchmove', this.onDrag);
      window.addEventListener('mouseup', this.stopDrag);
      window.addEventListener('touchend', this.stopDrag);
    },
    onDrag(event) {
      if (!this.isDragging) return;

      const clientY = event.clientY || event.touches[0].clientY;
      const deltaY = this.startY - clientY;
      this.drawerHeight = Math.min(
        Math.max(this.startHeight + deltaY, 100), // Minimum height
        window.innerHeight * 0.8 // Maximum height (80% of screen)
      );
    },
    stopDrag() {
      this.isDragging = false;

      // Remove event listeners
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('touchmove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDrag);
      window.removeEventListener('touchend', this.stopDrag);
    },
    checkIfMobile() {
      // Check if the screen width is less than 768px (mobile)
      this.isMobile = window.innerWidth < 768;
      this.isBigMobile = window.innerWidth < 1025;
    },
  },
  created() {
    this.checkIfMobile(); // Initial check
    window.addEventListener('resize', this.checkIfMobile); // Update on resize

    EventBus.$on('hideHeaderBackground', (payload) => {
      this.hideHeaderBackground = payload.status;
    });
    if (
      this.$route.path === '/app/explore/search-landing' ||
      this.$route.path === '/app/explore/all-search-results'
    ) {
      this.handleHideSidebar(true);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile); // Cleanup
    EventBus.$off('hideHeaderBackground');
  },
  watch: {
    $route(to) {
      if (to.path === '/app/explore' || to.path === '/app/explore/') {
        this.handleHideSidebar(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f9fbfd;
  padding-bottom: 20px;
  width: 100%;
}

.show-header-back {
  z-index: 88;
}
.hide-header-back {
  z-index: 0;
}

/* Drawer Styles (Mobile Only) */
.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  transition: height 0.3s ease;
}

.drawer-handle {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 50;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f9fbfd;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;

  svg {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }
}

/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  .header {
    padding: 10px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .header {
    padding: 15px;
  }
}

@media only screen and (min-width: 769px) {
  .header {
    padding: 20px;
  }

  /* Hide drawer on tablets and desktops */
  .drawer {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu {
    display: none;
  }
}
</style>
